/***************************************
 GMAP
 ****************************************/

$(document).ready(function ($) {
	if ($('#gmap').length) {
		initGmap();
	}
});

function initGmap() {
	var site = new google.maps.LatLng(40.7250601, -73.995976, 18);
	var center = site;
	var marker;
	var map;

	var styles = [{"featureType":"landscape","stylers":[{"hue":"#FFBB00"},{"saturation":43.400000000000006},{"lightness":37.599999999999994},{"gamma":1}]},{"featureType":"road.highway","stylers":[{"hue":"#FFC200"},{"saturation":-61.8},{"lightness":45.599999999999994},{"gamma":1}]},{"featureType":"road.arterial","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":51.19999999999999},{"gamma":1}]},{"featureType":"road.local","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":52},{"gamma":1}]},{"featureType":"water","stylers":[{"hue":"#0078FF"},{"saturation":-13.200000000000003},{"lightness":2.4000000000000057},{"gamma":1}]},{"featureType":"poi","stylers":[{"hue":"#00FF6A"},{"saturation":-1.0989010989011234},{"lightness":11.200000000000017},{"gamma":1}]}];

	var mapOptions = {
		zoom: 14,
		center: center,
		draggable: true,
		scrollwheel: false,
		disableDoubleClickZoom: true,
		panControl: false,
		zoomControl: true,
		scaleControl: false,
		mapTypeControl: false,
		streetViewControl: false,
		overviewMapControl: false,
		mapTypeId: google.maps.MapTypeId.ROADMAP
	};

	var contentString = '<div class="popup-contact">' +
			'<ul>' +
			'<li>Město, 123 45</li>' +
			'<li>Ulice 123</li>' +
			'</ul>' +
			'</div>';

	var infowindow = new google.maps.InfoWindow({
		content: contentString,
		size: new google.maps.Size(50, 50)
	});

	var image = new google.maps.MarkerImage(
			'/static/img/gmap.png',
			new google.maps.Size(64, 88),
			new google.maps.Point(0, 0),
			new google.maps.Point(32, 88)
			);

	/*var shadow = new google.maps.MarkerImage(
	 '/static/img/gmap/shadow.png',
	 new google.maps.Size(95,56),
	 new google.maps.Point(0,0),
	 new google.maps.Point(32,56)
	 );*/

	map = new google.maps.Map(document.getElementById("gmap"), mapOptions);

	marker = new google.maps.Marker({
		map: map,
		draggable: false,
		position: site,
		icon: image
				//shadow: shadow
	});

	var styledMapOptions = {
		map: map
	};

	var mainmap = new google.maps.StyledMapType(styles, styledMapOptions);

	map.mapTypes.set('site_map', mainmap);
	map.setMapTypeId('site_map');

	google.maps.event.addListener(marker, 'click', function () {
		infowindow.open(map, marker);
	});
}

$(document).ready(function ($) {

	// Fixed header
	$(window).on('load scroll',function(){
		var elTop = $('.header-wrapper').offset().top;
		var scrollTop = $(window).scrollTop();

		if ( elTop < scrollTop ) {
			$('.header').addClass('fixed')
		}
		else {
			$('.header').removeClass('fixed');
		}
	});

	// qTip
	$('.qtip-info').each(function() {
		$(this).qtip({
			content: {
		        text: $(this).siblings('.qtip-info-content')
		    },
		    position: {
		        my: 'bottom center',
		        at: 'top center',
		        adjust: {
		            y: -10
		        }
		    },
		    style: {
		        tip: {
		            width: 16,
		            height: 10
		        }
		    }
		});
	});

	/**********************************************
	RESIZE END / SCROLL END
	**********************************************/
	var resizeEnd;
	$(window).on('resize', function () {
		clearTimeout(resizeEnd);
		resizeEnd = setTimeout(function () {
			$(window).trigger('resizeEnd');
		}, 100);
	});

	var scrollEnd;
	$(window).on('scroll', function () {
		clearTimeout(scrollEnd);
		scrollEnd = setTimeout(function () {
			$(window).trigger('scrollEnd');
		}, 100);
	});

});

function jQuerySelectorEscape(expression) {
	return expression.replace(/[!"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, '\\$&');
}

// Zobrazení elementu ve viewportu
function inViewport(el) {
	var elH = $(el).height(),
			elTop = $(el).offset().top,
			scrollTop = $(window).scrollTop(),
			winH = $(window).height();
	fixedHeaderH = 0; // výška fixní hlavičky

	return ((elTop + elH) > (scrollTop + fixedHeaderH) && (scrollTop - elH) > (elTop - winH));
}